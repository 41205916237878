import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Privacy Policy">
    <section className="section-privacy-policy">
      <div className="content_fullwidth">
        <div className="one_full">
          {/*<h3>For Actions on Google</h3>*/}
          <p>DD Zone, Inc. Ltd. built the Digital Diary app as a Commercial app. This SERVICE is provided by DD Zone,
            Inc. and is intended for use as is.
          </p>
          <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of
            Personal Information if anyone
            decided to use our Service.
          </p>
          <p>If you choose to use our Service, then you agree to the collection and use of information in relation to
            this policy. The Personal
            Information that we collect is used for providing and improving the Service. We will not use or share your
            information with anyone except as
            described in this Privacy Policy.
          </p>

          <p>DD Zone receives in connection with the user support services we provide to the user (the "Services"). When
            you submit information through
            our website, email, chat session, provide it to our technicians or, use the Services, you (the "user") agree
            to our use of this information
            consistent with this privacy policy.</p>

          <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is
            accessible at Digital Diary unless
            otherwise defined in this Privacy Policy.
          </p>

          <h4>What information do we collect?</h4>

          <p>We need information that personally identifies you ("personal information") or allows us to contact you. In
            order to use the Services, the
            user must first typically register or provide certain identifying information.
          </p>
          <p>We also collect the content that you create, upload or receive from others when using our services.</p>

          <p>For online registration, we ask you to provide contact details (such as First and Last name, E-mail
            address, Postal address, Personal
            identifier etc.).
          </p>
          <p><b>CAMERA</b><br/>Scan QR, Face Attendance, Capturing Photos, Scanning VCard or document.</p>
          <p><b>PHONE</b><br/>To call the person via Phone Dialer used in Ride and Vcard.</p>
          <p><b>CONTACTS</b><br/>To add your colleagues to vcard from your contacts.</p>
          <p><b>LOCATION</b><br/>Get current location while adding address, marking attendance via
            location(geofence, face, manual via pin)</p>
          <p><b>MEDIA</b><br/>Upload photos/pdf to app or download photos/pdf or xls.</p>
          <p><b>NOTIFICATION</b><br/>To receive or schedule notifications used in ride and parking management.</p>
          <p><b>BLUETOOTH</b><br/>Scans near by available devices to auto connect previously connected stipe terminals
            or smart switches. Connect to Stripe terminal for payments and EV smart switch devices.</p>
          <p><b>AUDIO</b><br/>Record Audio used in Exchange contact while you want to create Leads for your business.</p>


          <h4>How do we use the information?</h4>
          <p>We use your information to deliver our services, such as processing the terms you search for in order to
            return results or helping you select
            content by suggesting options based on your information. And we use your information to make improvements to
            our services.
          </p>
          <p>We use this information to contact you regarding the product and/or services you have requested. This
            includes re-registration/renewal
            notifications, special offers, and surveys on improving our Services.</p>
          <h4>What information do we share?</h4>

          <p>We don’t share information that personally identifies you with advertisers.
          </p>
          <p>The app does use third-party services that may collect information used to identify you.</p>
          <p>Link to the privacy policy of third party service providers used by the app</p>
          <ul>
            <li><a href="https://www.google.com/policies/privacy/">Google Play Services</a>
            </li>
          </ul>

          <p>Some data you can delete whenever you like, such as the content that you create or upload. For a better
            experience, while using our Service,
            we may require you to provide us with certain personally identifiable information. The information that we
            request will be retained by us and
            used as described in this privacy policy.</p>

          <h4>Log Data</h4>
          <p>We want to inform you that whenever you use our Service, in a case of an error in the app we collect data
            and information (through third
            party products) on your phone called Log Data. This Log Data may include information such as your device
            Internet Protocol (“IP”) address,
            device name, operating system version, the configuration of the app when utilizing our Service, the time and
            date of your use of the Service,
            and other statistics.

          </p>
          <h4>Cookies</h4>
          <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These
            are sent to your browser from the
            websites that you visit and are stored on your device's internal memory.</p>
          <p>This Service does not use these “cookies” explicitly. However, the app may use third party code and
            libraries that use “cookies” to collect
            information and improve their services. You have the option to either accept or refuse these cookies and
            know when a cookie is being sent to
            your device. If you choose to refuse our cookies, you may not be able to use some portions of this
            Service.</p>
          <h4>Service Providers</h4>
          <ul>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
          <p>We want to inform users of this Service that these third parties have access to your Personal Information.
            The reason is to perform the tasks
            assigned to them on our behalf. However, they are obligated not to disclose or use the information for any
            other purpose.
          </p>
          <h4>Security</h4>
          <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially
            acceptable means of protecting it.
            But remember that no method of transmission over the internet, or method of electronic storage is 100%
            secure and reliable, and we cannot
            guarantee its absolute security.
          </p>
          <h4>Links to Other Sites</h4>
          <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to
            that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of
            these websites. We have no control
            over and assume no responsibility for the content, privacy policies, or practices of any third-party sites
            or services.</p>
          <h4>Children’s Privacy</h4>
          <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally
            identifiable information from children under
            13. In the case we discover that a child under 13 has provided us with personal information, we immediately
            delete this from our servers. If
            you are a parent or guardian and you are aware that your child has provided us with personal information,
            please contact us so that we will be
            able to do necessary actions.
          </p>
          <h4>Changes to This Privacy Policy</h4>
          <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically
            for any changes. We will notify
            you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately
            after they are posted on this page.
          </p>
        </div>

        {/*<div className="clearfix mar_top3">
                <h3>For Other DD Zone Products</h3>
                <p>This privacy policy explains how DD Zone collects, uses and discloses personal and other information DD Zone receives in connection with the
                  user support services we provide to user (the "Services"). When you submit information through our website, email, chat session, provide it to
                  our technicians or, use the Services, you (the "user") agree to our use of this information consistent with this privacy policy.
                </p>
              </div>*/}
        <div className="one_full">
          <h4>How DD Zone collects information</h4>
          <p>We provide user support services to consumers using web-based tools and software as well as through
            telephone support of our users. These
            tools may include software that enables us to remotely access and control a user’s computer with their permission. We require certain
            information and permissions from you to provide the Services.</p>
          <blockquote>Some information we collect may be provided by the user directly such as when requested by a form or on the phone by a technician.
            Other technical information might be collected or accessed by or through our diagnostic and related tools as our computers work with yours to
            provide the Services.
          </blockquote>
          <p></p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Types of information DD Zone collects </h4>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Personal information: </h4>
          <p>We will ask you when we need information that personally identifies you ("personal information") or allows us to contact you. In order to use
            the Services, the user must first typically register or provide certain identifying information. For online registration, we ask you to
            provide contact details (such as First and Last name, E-mail address, Postal address, Personal identifier etc.). DD Zone uses this information
            to contact you regarding the product and/or services you have requested. This includes re registration/renewal notifications, special offers,
            and surveys on improving our Services.</p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Payment information:</h4>
          <p>If you register for the Services, DD Zone may collect credit card information, and other payment information. Like many companies, DD Zone
            may use third party payment processing, payment gateways transactions to process and verify credit cards on our behalf. DD Zone uses payment
            information to complete your transactions. </p>
          {/*<div className="clearfix mar_top2"></div>
                <h4>Computer and System Information</h4>*/}
        </div>
        <div className="clearfix mar_top1"></div>
        <div className="one_full">
          <h4>Subscriber( user) computer information: </h4>
          <p>We may also ask for information related to your (user) computer to provide the Service. As examples, this information may include: </p>
          <div className="clearfix mar_top1"></div>
          <ul>
            <li>Information about the date of purchase of your computer.</li>
            <div className="clearfix mar_top1"></div>
            <li>Type of computer.</li>
            <div className="clearfix mar_top1"></div>
            <li>Identification number of your computer make and model of your computer and/or any computer hardware, software installed or peripherals
              attached to it, condition of the computer, system and registry data about software installations and hardware configurations, and error
              tracking files.
            </li>
          </ul>
          <div className="clearfix mar_top1"></div>
          <p>Generally, this information is required to provide personalized technical support to you, and to help us update our support tools and enhance
            our supported products lists. </p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Remote access: </h4>
          <p>While requesting support you may request for the problem to be resolved by enabling DD Zone technicians to have access to and take control of
            your computer. DD Zone uses software tools which enable you to grant control of your computer to an DD Zone technician remotely over the
            Internet. This allows the DD Zone technician to diagnose or correct problems without ever coming to the location of your computer.</p>
          <div className="clearfix mar_top1"></div>
          <p>DD Zone does not use the Remote Access software to access and control your computer without your consent and we request that you remain
            present when our technicians are doing so. DD Zone technicians are trained in the use of this software and we take steps to limit access to
            confidential or sensitive information stored on user computer or network, avoid deliberate destruction of information on user computers or
            networks, and help avoid system problems. DD Zone maintains specific internal policy and technical controls to limit how and whether our
            remote access tools may be used to assist you. </p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Diagnostic tool: </h4>
          <p>DD Zone uses some online diagnostic tolls that may collect a wide variety of useful information about the state of a computer system and your
            applications. This information is communicated to DD Zone using security technologies over the Internet. DD Zone analyzes this information to
            help diagnose and solve end-user problems. The DD Zone diagnostic application is not designed to collect sensitive information including
            information such websites visited, e-mail messages, e-mail addresses sent to, passwords, profiles, etc.</p>
          <div className="clearfix mar_top1"></div>
          <p>Use of the Remote Access and Diagnostic applications may be subject to the terms and conditions of the respective software license
            agreements.</p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Live help session records: </h4>
          <p>DD Zone reserves the right to monitor and record on-line and off-line sessions between users and DD Zone technicians for quality control and
            service-related purposes. Some uses of this type of information include improving the Service, building a support knowledge base and
            conducting internal market research. We do not correlate session record data used for these purposes with personal information.</p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Cookies:</h4>
          <p>We may use cookies and similar technologies to provide you with more personal service on our website and as you interact with DD Zone.
            Cookies are small pieces of information stored by your browser on your computer's hard drive or on your device. Cookies help us keep track of
            the preferences you specify. Most web browsers automatically accept cookies, but you can usually change your browser to prevent that if you
            prefer not to store the information for future use. Each browser is different, so please check the help menu of your browser.</p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>LOG FILES: </h4>
          <p>We use IP addresses to analyze trends, administer the website, track user movement, and gather broad demographic information for aggregate
            use.</p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Supplementation of information</h4>
          <p>In order to protect DD Zone from fraud and otherwise ensure the integrity of our services and manage risk we may supplement personal
            information we collect with information we may get from others. For example, in connection with determining creditworthiness, we may use your
            name and other related information to request a credit report as permitted by law.</p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Sharing and information disclosure </h4>
          <p>We may share aggregated and de-identified information with our partners. This sharing is usually not linked to any personal information that
            can identify any individual person, unless specifically required to allow use of specific widgets within the Services. We may also share,
            disclose, or transfer your personal information as follows: </p>
          <div className="clearfix mar_top1"></div>
          <ul>
            <li>To DD Zone affiliates and subsidiaries to support business operations and sales, marketing, and customer support processes;</li>
            <div className="clearfix mar_top1"></div>
            <li>To third party service providers and suppliers acting on our behalf to provide products or services to you; and</li>
            <div className="clearfix mar_top1"></div>
            <li>To other third parties for purposes you have allowed.</li>
          </ul>
          <div className="clearfix mar_top1"></div>
          <p>Additionally, we may also disclose your personal information as we determine reasonably necessary to (a) comply with applicable law,
            regulations, legal processes, or enforceable governmental requests, (b) respond as necessary to actual or potential lawsuits, or (c) protect
            the rights and property of DD Zone or others. We reserve the right to retain, use, and disclose aggregate or anonymous information developed
            from personal information at any time. If we merge with, or are acquired by another company, or sell all or substantially all of our relevant
            assets, or file for bankruptcy, we may disclose and transfer personal information with the company or asset. We will seek appropriate
            protection for personal information disclosed or transferred in such transactions consistent with applicable law. </p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Global users</h4>
          <p>Because DD Zone operates globally, we make information we gather available to our worldwide business units. If you are in the European
            Economic Area, please note that the personal information you provide will be transferred outside the European Economic Area for use by DD Zone
            and its affiliates and subsidiaries for any of the purposes described in this privacy policy. By using the DD Zone website, using the
            Services, or otherwise providing us with your personal information, you consent to the transfer and processing of your personal
            information.</p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Security</h4>
          <p>We use a variety of security technologies, as well as administrative, managerial, and physical safeguards, to help protect personal
            information and other data we receive from unauthorized access, use, or disclosure. No data transmissions, or even the physical transfer of
            information, can be guaranteed to be completely secure. We cannot fully eliminate all security risks associated with personal information and
            technical mistakes are possible. Therefore, we do not ensure or warrant the security of any data or information you transmit to us and you do
            so at your own risk.</p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Surveys</h4>
          <p>From time-to-time we may request information from users via surveys. Participation in these surveys is completely voluntary and users have a
            choice whether or not to participate. Information requested may include contact information (such as name and shipping address), and
            demographic information (such as zip code). Survey information will be used for purposes of monitoring or improving the use and satisfaction
            of Services. </p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Contests</h4>
          <p>DD Zone may offer contests, sweepstakes or other promotions. These promotions will be governed by applicable rules. Personal information
            collected through such promotions will be used as described at the time of collection.</p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Children</h4>
          <p>Our Services are not intended for use by anyone under the age of 13. Consistent with the Children's Online Privacy Protection Act, we do not
            collect or maintain information at our website from or sell products to those we actually know are under the age of 13. </p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Correction/updating personal information</h4>
          <p>If a user's personal information changes (such as user zip code), or if a user no longer desires to use the Service. User may correct, update
            or remove user personal information provided to us through our website.</p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Notification of changes</h4>
          <p>We reserve the right to change our privacy policy. Please check our website periodically for changes so that you are aware of our latest
            privacy practices. For changes that are materially less restrictive or protective of your personal information than the privacy policy in
            place at the time of collection, we will notify before implementing any such change.</p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Questions</h4>
          <p>If you have any questions about our privacy policy you may email questions or inquiries to <a
            href="mailto:support@DD Zone.co.in">support@DD Zone.co.in</a></p>
        </div>
        <div className="clearfix mar_top3"></div>
      </div>
      <br/>

    </section>
  </Layout>
);

export default Page;

/*

        <!-- <abbr title="Mobile Phone">M:</abbr> 	(91) 9893-755100<br/>-->
 */
